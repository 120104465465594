@import "shared/scss/helpers/index";
@import "shared/scss/animations/AppereanceAnimation";

.title {
  margin: 25px auto;
  color: black;

  @include breakpoint(xs) {
    font-size: 40px;
    line-height: 40px;
  }

  @include breakpoint(sm) {
    min-width: 600px;
  }

  @include breakpoint(md) {
    animation: apperanceAnim 1.2s linear;
    font-size: 85px;
    line-height: 85px;
  }
}
