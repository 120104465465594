@import "shared/scss/helpers/index";

.beatsWrapper {
  width: 100%;
  max-width: 675px;
  margin: 0 auto;
  transition: 1.2s;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  background: url("../../../../static/img/background/coverCrop2.jpg") center/cover no-repeat;

  @include breakpoint(sm) {
    gap: 5px;
  }

  @include breakpoint(md) {
    opacity: 0;

    &__visible {
      opacity: 1;
    }
  }
}

// BeatCard

.beatCard {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  width: calc(33% - 5.4px);
  height: 0;
  padding: 0 0 33%;
  border: 1px solid white;
  background: none;
  text-align: left;
  padding-left: 10px;
  transition: 0.4s ease;
  border-radius: 0px;
  position: relative;

  .beatCardLetter {
    font-size: 38px;
    line-height: 28px;
    color: $white;
    font-family: $fd;
    position: absolute;
    top: 8px;
    left: 8px;
    transition: 0.4s ease;
  }

  @include hovering() {
    &:hover {
      filter: invert(100%) sepia(100%);

      .beatCardLetter {
        color: $black;
        filter: invert(100%) sepia(100%);
      }
    }
  }

  &.beatCardActive {
    filter: invert(100%) sepia(100%);
  }

  @include breakpoint(sm) {
    width: calc(25% - 4px);
    padding: 0 0 25%;
    opacity: 1;

    &.beatCardActive {
      .beatCardLetter {
        color: $black;
        filter: invert(100%) sepia(100%);
      }
    }
  }
}