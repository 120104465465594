@import "shared/scss/helpers/index";

.content {
  width: calc(100% - 30px);
  margin: 0 auto;
  padding-top: 42px;
  max-width: 675px;
  @include breakpoint(sm) {
    width: calc(100% - 50px);
    margin: auto;
    padding-top: 80px;
  }
  @include breakpoint(lg) {
    width: 675px;
    padding-top: 5px;
  }

  position: relative;
}

.wrapper {
  position: relative;
  overflow-x: hidden;
}

.contentWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  min-width: 360px;
  flex-direction: column;
  align-items: center;
  transition: 0.4s;
  @include max-breakpoint(xss) {
    overflow-x: scroll;
  }
}

.noize {
  pointer-events: none;

  position: fixed;
  z-index: 1;
  inset: -50%;

  width: 200%;
  height: 200vh;

  visibility: visible;
  opacity: 0.15;
  background: url("../../../../static/img/background/noize.png") repeat center;
}
