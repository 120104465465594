@import "shared/scss/helpers/index";
@import "shared/scss/animations/AppereanceAnimation";

.outsideWrapper {
  padding-bottom: 86px;
  transition: 1.5s;

  @include breakpoint(md) {
    animation: apperanceAnim 1.2s linear;
  }

  @include breakpoint(sm) {
    padding-bottom: 120px;
  }

  @include breakpoint(lg) {
    padding-bottom: 86px;
  }
}

.downloadLink {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  color: $black;
  transition: 0.3s all;
  font-size: 20px;
  line-height: 24px;
  font-family: $fd;

  span {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background: $black;
      transition: 0.3s all;
    }
  }

  svg {
    transition: 0.3s all;

    * {
      transition: 0.3s all;
    }
  }

  @include hovering() {
    &:hover {
      svg {
        fill: $white;

        *:not(path.spec) {
          fill: $white;
        }
      }

      span {
        &::before {
          background: $white;
        }
      }

      color: $white;
    }
  }

  @include breakpoint(sm) {
    bottom: 85px;
  }

  @include breakpoint(lg) {
    bottom: 45px;
  }

  .svgLogo {
    margin-right: 14px;
  }
}